import React, { useState } from 'react'
import { ArrowDropDown} from '@mui/icons-material';
function Accordian({text,desc,IconComponent}) {
    const [Toggle, setToggle] = useState(false)
  return (
    
                <div className='w-full max-w-[650px]'>
            <div className='flex justify-between items-center border-t-2 border-gray-200 px-5 py-6 hover:bg-secondary hover:text-white'>
                <div className='flex justify-center items-center space-x-8'>
                    <p>{ <IconComponent/>}</p>
                    <p className='font-playfair'>{text}</p>
                </div>
                <div onClick={()=>setToggle(!Toggle)}> <ArrowDropDown></ArrowDropDown></div>
            </div>
            <div className={`${Toggle?'block':'hidden'} text-xs bg-gray-100 p-3 px-8 transition-all duration-1000 `}>{desc}</div>
        </div>
  )
}

export default Accordian