import React from 'react'

function Review() {
  return (
    <div className='w-full h-full max-h-64 flex py-10 bg-text-base  justify-around items-center'>
        <div className='w-full sm:w-1/2 px-10 h-full flex justify-between items-center'>
            <div className='text-start w-20'>
                <h className='text-4xl' >10+</h>
                <p className='text-xs px-2'>All Over World</p>
            </div>
            <div className='text-start w-20'>
                <h className='text-4xl' >20+</h>
                <p className='text-xs '>Products Available</p>
            </div>
            <div className='text-start w-20'>
                <h className='text-4xl' >598</h>
                <p className='text-xs px-1'>Product Reviews</p>
            </div>
        </div>
        <div className=' hidden sm:flex sm:w-2/5 text-4xl text-start font-playfair font-medium text-transparent bg-clip-text bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#d6922c] to-[#141008] '><h1 className='w-80'>We Offer The Best Elegant Style</h1></div>
    </div>
  )
}

export default Review