import React from 'react'
import ProductCard from './ProductCard'
import product1 from '../assets/product1.jpeg'







function ProductCorousel() {
  const products= [
    {
      img:require('../assets/product1.jpeg'),
      title:'Diamond Pendant',
      price:'23.45'
    },
    {
      img:require('../assets/product2.jpeg'),
      title:'Diamond Pendant',
      price:'23.45'
    },
    {
      img:require('../assets/product3.jpeg'),
      title:'Diamond Pendant',
      price:'23.45'
    },
    {
      img:require('../assets/product4.jpeg'),
      title:'Diamond Pendant',
      price:'23.45'
    },
    {
      img:require('../assets/product5.jpeg'),
      title:'Diamond Pendant',
      price:'23.45'
    },
    {
      img:require('../assets/product6.jpeg'),
      title:'Diamond Pendant',
      price:'23.45'
    },
    {
      img:require('../assets/product7.jpeg'),
      title:'Diamond Pendant',
      price:'23.45'
    },

  ]

  return (
    <div className='w-full h-[450px] flex bg-gray-100 overflow-x-scroll'>

      {
      products.map(product=>
        <ProductCard img ={product.img} title={product.title} ></ProductCard>

        )
        }
   

    </div>
      //   <div>
      //   {
      //     products.map(product => <div>
      //       <h1>{product.price}</h1>
      //     </div>)
      //   }
      // </div>
    
  )
}

export default ProductCorousel