import React from 'react'
import ProductCard from '../components/ProductCard'

function Products() {
  const products= [
    {
      img:require('../assets/product1.jpeg'),
      title:'Diamond Pendant',
      price:'23.45'
    },
    {
      img:require('../assets/product2.jpeg'),
      title:'Diamond Pendant',
      price:'23.45'
    },
    {
      img:require('../assets/product3.jpeg'),
      title:'Diamond Pendant',
      price:'23.45'
    },
    {
      img:require('../assets/product4.jpeg'),
      title:'Diamond Pendant',
      price:'23.45'
    },
    {
      img:require('../assets/product5.jpeg'),
      title:'Diamond Pendant',
      price:'23.45'
    },
    {
      img:require('../assets/product6.jpeg'),
      title:'Diamond Pendant',
      price:'23.45'
    },
    {
      img:require('../assets/product7.jpeg'),
      title:'Diamond Pendant',
      price:'23.45'
    },

  ]
  return (
    <div className='flex justify-center bg-white items-center p-14 flex-wrap gap-x-7 gap-y-9 '>
      {
      products.map(product=>
        <ProductCard img ={product.img} title={product.title} ></ProductCard>

        )
        }
    </div>
  )
}

export default Products