import React from 'react'
import bv16 from '../../assets/bv16.jpeg'
import { Link } from 'react-router-dom'

function Hero() {
  return (
    <div className='w-full h-full px-5 py-10 sm:p-10 bg-text-base flex flex-col justify-center items-center space-y-10 '>
        <div className='uppercase text-5xl py-0 pt-5 sm:pt-5 sm:text-6xl  min-w-lg max-w-lg sm:max-w-3xl font-playfair text-transparent bg-clip-text bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#d6922c] to-[#141008] '>Moments Made with Silver Elegance</div>
        <div className='w-full h-full sm:h-[26rem] sm:max-h-[40rem] flex flex-col justify-center  sm:flex-row smspace-x-5 sm:justify-around items-center'>
            <div className=' h-full sm:h-80  sm:w-1/2 flex justify-center items-center object-fit overflow-hidden'>
                <img className='w-100 h-100 sm:h-[40rem] sm:w-[60rem] object-contain ' src={bv16} alt="" />
                </div>
            <div className='w-full sm:w-1/2 sm:px-10 sm:text-start sm:items-start flex h-full flex-col justify-center items-center space-y-5 '>
                <h1 className='text-3xl sm:text-2xl font-playfair '>Discover Our Exquisite Silver Jewelry</h1>
                <p className='text-base sm:text-base'>Our silver jewelry collection features meticulously crafted rings and lockets that blend timeless elegance with modern design.Each piece is a testament to our commitment to quality and craftsmanship.Discover the perfect balance of classic beauty and contemporary flair.</p>
                <Link to='/products'><div className='p-2 border-2 w-fit border-secondary'> Discover Now</div></Link>

            </div>
        </div>
    </div>
  )
}

export default Hero