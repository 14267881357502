import React from 'react'
import WB1 from '../assets/product3.jpeg'
import { Link } from 'react-router-dom';
function Footer() {
  return (
    <div>
      {/* dekstop version */}
    <div className='hidden md:block pt-20 h-full max-h-[800px] bg-white relative overflow-hidden '>
        <div className='px-20 space-y-9 mb-[-90px] z-1'>
            <div className='space-y-3 flex flex-col justify-center items-center'>
                <p className='text-sm font-playfair text-[#f0a646]'>Let's Know About Us</p>
                <h1 className='text-4xl font-playfair text-transparent bg-clip-text bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#d6922c] to-[#141008] font-medium'>Don't Miss Any Update</h1>
                <p className='text-xs w-80'>Join us on Instagram for fresh designs, exclusive deals, and your next favorite piece!</p>
            </div>
            <div className='flex justify-center items-center font-playfair '>
            <div className='mr-3 w-44 h-56  overflow-hidden bg-orange-400 relative'>
                  <img  className='object-cover w-full h-full' src={WB1} alt="" />
                  <div className='absolute inset-0 bg-gray-700 opacity-0 hover:opacity-100 transition-opacity flex justify-center items-center text-white'><Link to='https://www.instagram.com/silwery.in/'> Silwery </Link></div>
                </div>
                <div className='mr-3 w-44 h-56  overflow-hidden bg-orange-400 relative'>
                  <img  className='object-cover w-full h-full' src={WB1} alt="" />
                  <div className='absolute inset-0 bg-gray-700 opacity-0 hover:opacity-100 transition-opacity flex justify-center items-center text-white '><Link to='https://www.instagram.com/silwery.in/'> Silwery </Link></div>
                </div>
                <div className='mr-3 w-44 h-56  overflow-hidden bg-orange-400 relative'>
                  <img  className='object-cover w-full h-full' src={WB1} alt="" />
                  <div className='absolute inset-0 bg-gray-700 opacity-0 hover:opacity-100 transition-opacity flex justify-center items-center text-white'><Link to='https://www.instagram.com/silwery.in/'> Silwery </Link></div>
                </div>
                <div className='mr-3 w-44 h-56  overflow-hidden bg-orange-400 relative'>
                  <img  className='object-cover w-full h-full' src={WB1} alt="" />
                  <div className='absolute inset-0 bg-gray-700 flex justify-center items-center text-white cursor-pointer '><Link to='https://www.instagram.com/silwery.in/'> Silwery </Link></div>
                </div>


            </div>
        </div>
        <div className='bg-secondary h-full pt-36 pb-10 text-gray-200 px-24'>
          <div className='w-full flex justify-between text-left text-sm'>
            <div className='left '>
              <h1 className='text-2xl'>SILWERY</h1>
              <p className='w-72'>Discover the perfect piece that speaks to you and make it yours with just a click.</p>
            </div>
            <div className='right flex list-none space-x-9'>
              <div className='space-y-2'>
             <Link to='/about'><li  >About us</li></Link>
             <Link to='/contact'> <li>Contact us</li></Link>
             <Link to='/contact'><li>Collab</li></Link>
             <Link to='/products'><li>Top Product</li></Link>
              </div>
              <div className='space-y-2'>
              <Link to='/silwery++'><li>Silwery+</li></Link>
              <Link to='https://www.instagram.com/silwery.in/'> <li>Instagram</li></Link>
              <Link><li>Gmail</li></Link>
              </div>
            </div>

          </div>
          <div className='mt-6 py-2 border-t-[1px] border-gray-700 flex justify-between items-center text-xs text-gray-300'>
            <p>@2023 Silwery all rights reserved</p>
            <div className='flex space-x-3 list-none'>
              <li>Terms and Conditions</li>
              <li>Pirvacy Policy</li>
            </div>
            </div>

        </div>
    </div>

    {/* mobile version */}

    <div className='md:hidden pt-20 h-full w-full max-h-[800px] bg-white relative'>

    <div className='px-14 w-full space-y-9 mb-[-120px] z-1 flex flex-col items-center '>
    <div className='space-y-3 flex flex-col justify-center items-center'>
                <p className='text-xs font-playfair'>Let's Know About Us</p>
                <h1 className='text-4xl font-playfair text-transparent bg-clip-text bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#d6922c] to-[#141008] font-medium'>Don't Miss Any Update</h1>
                <p className='text-xs w-72'>Join us on Instagram for fresh designs, exclusive deals, and your next favorite piece!</p>
            </div>
            <div className='mr-3 w-44 h-56  overflow-hidden bg-orange-400 relative'>
                  <img  className='object-cover w-full h-full' src={WB1} alt="" />
                  <div className='absolute inset-0 bg-teal-800 opacity-0 hover:opacity-100 transition-opacity flex justify-center items-center text-white'> <Link to='https://www.instagram.com/silwery.in/'> Silwery </Link></div>
                </div>
    </div>
    <div className='bg-secondary w-full h-full px-5 pt-36 text-gray-400 text-xs space-y-4 list-none text-start font-playfair '>
    <div className='left '>
              <h1 className='text-2xl'>SILWERY</h1>
              <p className='w-64 '>Discover the perfect piece that speaks to you and make it yours with just a click.</p>
            </div>
            
            <div className='space-y-2'>
            <Link to='/about'><li  >About us</li></Link>
             <Link to='/contact'> <li>Contact us</li></Link>
             <Link to='/contact'><li>Collab</li></Link>
             <Link to='/products'><li>Top Product</li></Link>
              </div>

              <div className=' flex justify-start items-center'>
              <Link to='/silwery++'><li>Silwery+</li></Link>
              <Link to='https://www.instagram.com/silwery.in/'> <li>Instagram</li></Link>
              <Link><li>Gmail</li></Link>
              </div>


    <div className='mt-6 py-2 border-t-[1px] border-gray-700 flex justify-between items-center text-[0.5rem] text-gray-300'>
            <p>@2023 Silwery all rights reserved</p>
            </div>

    </div>
    </div>

    </div>
  )
}

export default Footer