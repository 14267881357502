import React from 'react'
import Hero from '../components/home/Hero'
import Review from '../components/Review'
import WBProduct from '../components/home/WBProduct'
import BestSellingProduct from '../components/BestSellingProduct'
import Footer from '../components/Footer'
import FAQ from '../components/FAQ'

function Home() {
  return (
    <div>
      <Hero></Hero>
      <Review></Review>
      <WBProduct></WBProduct>
      <BestSellingProduct></BestSellingProduct>
      <FAQ></FAQ>
      <Footer></Footer>

    </div>
  )
}

export default Home