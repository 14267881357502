import React from 'react'
import { Link } from 'react-router-dom'
function ProductCard({title,img}) {
  const card_height = '280px'
  const card_width = '300px'
  const card_bg = 'white'
  return (
    <div className=' mx-2 max-h-[420px] min-w-[240px] max-w-[240px] bg-primary rounded-md font-playfair'style={{width:card_width}}  >
      <img className=' object-cover mb-4' style={{ width:card_width, height:card_height}} src={img} alt="" />
        <div className=' w-full px-4 py-5 text-left sm:px-3 flex justify-between'>
            <div className='uppercase font-bold text-gray-800'>{title}</div>
            <div className='w-full h-full flex flex-col items-end text-end space-y-3  '>
                <p className='text-[0.6rem] text-white bg-orange-500 p-[3px] px-[5px] rounded-sm w-fit'>Best Selling</p>
                <Link to='https://www.instagram.com/direct/t/17842005444013288'><p>Buy Now</p></Link>
            </div>
        </div>
    </div>
  )
}

export default ProductCard