import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import logo from '../assets/logo2.png'


function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  return (

    <nav className="bg-gray-50 sm:bg-white p-4">
    <div className="max-w-7xl mx-auto px-4 flex justify-between items-center">
      {/* Mobile Hamburger Menu */}
      <div className="md:hidden">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="text-black focus:outline-none"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            {isOpen ? (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            ) : (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            )}
          </svg>
        </button>
      </div>
      
      {/* Desktop Links */}
      <div className="hidden md:flex space-x-4">
        <ul className="flex space-x-4">
        <Link to='/' className="block text-black hover:text-yellow-800"> <li> Home</li></Link>
        <Link to='/products' className="block text-black hover:text-yellow-800"> <li> Products</li></Link>
        <Link to='/about' className="block text-black hover:text-yellow-800"> <li> About</li></Link>
        <Link to='/contact' className="block text-black hover:text-yellow-800"> <li> Contact us</li></Link>
        
        </ul>
      </div>
      
      {/* Center Logo */}
      <div className=" flex-grow flex sm:mr-[180px]  justify-center items-center">
      <Link to='/'><img className='w-[240px] max-w-full h-auto ' src={logo} alt="" /></Link>
      </div>
      
      {/* Right Text */}
      <div className="flex-shrink-0 hidden md:block">
        <Link to='/silwery++'><span className="text-black hover:text-yellow-800 ">Silwery++</span></Link>
        
      </div>
    </div>

    {/* Mobile Menu */}
    {isOpen && (
      <div className="md:hidden">
            <ul className="mt-2 space-y-2">
               <Link to='/' className="block text-black hover:text-yellow-800 px-2 py-1"> <li> Home</li></Link>
               <Link to='/products' className="block text-black hover:text-yellow-800 px-2 py-1"> <li> Products</li></Link>
               <Link to='/contact' className="block text-black hover:text-yellow-800 px-2 py-1"> <li> Contact us</li></Link>
               <Link to='/about' className="block text-black hover:text-yellow-800 px-2 py-1"> <li> About</li></Link>
               <Link to='/silwery++' className="block text-black hover:text-yellow-800 px-2 py-1"> <li> Silwery++</li></Link>
             </ul>
      </div>
    )}
  </nav>

// {/* <nav className="bg-white p-4">
//       <div className="max-w-7xl mx-auto px-4 flex justify-between items-center">
//         {/* Mobile Hamburger Menu (Right Side) */}
//         <div className="flex-shrink-0">
//           <button
//             onClick={() => setIsOpen(!isOpen)}
//             className="text-black focus:outline-none"
//           >
//             <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//               {isOpen ? (
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
//               ) : (
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
//               )}
//             </svg>
//           </button>
//         </div>
        
//         {/* Center Logo */}
//         <div className="flex justify-center flex-grow">
//           <a href="#" className="text-black font-bold text-xl">Your Logo</a>
//         </div>
        
//         {/* Placeholder for Right Text (to maintain layout) */}
//         <div className="hidden"></div>
//       </div>

//       {/* Mobile Menu */}
//       {isOpen && (
//         <div className="md:hidden">
//           <ul className="mt-2 space-y-2">
//             <Link to='/' className="block text-black hover:text-yellow-800 px-2 py-1"> <li> Home</li></Link>
//             <Link to='/products' className="block text-black hover:text-yellow-800 px-2 py-1"> <li> Products</li></Link>
//             <Link to='/contact' className="block text-black hover:text-yellow-800 px-2 py-1"> <li> Contact us</li></Link>
//             <Link to='/about' className="block text-black hover:text-yellow-800 px-2 py-1"> <li> About</li></Link>
//             <Link to='/silwery++' className="block text-black hover:text-yellow-800 px-2 py-1"> <li> Silwery++</li></Link>

    
//           </ul>
//         </div>
//       )}
//     </nav> */}
    // <div className='w-full bg-text-base h-14 flex justify-evenly items-center sticky top-0 z-50' >
    //   <div className='w-full hidden bg-white list-none sm:flex justify-evenly'>

        

    //     <Link to='/'>Home</Link>
    //     <Link to='/products'>Products</Link>
    //     <Link to='/contact'>Contact Us</Link>
    //     <Link to='/about'>About</Link>
    //   </div>
    //   <div className='w-full flex items-center justify-center'>
    //     <Link to='/'><img className='w-[240px]' src={logo} alt="" /></Link>
    //     </div>
    //   <div className='w-full hidden list-none sm:flex justify-center space-x-10'>
    //   <Link to='/silwery++'>Silwery++</Link>
    //   </div>
    // </div>
  ) 
}

export default Navbar