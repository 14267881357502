import React from 'react'
import bv2 from '../../assets/bv2.jpeg'
import bv13 from '../../assets/bv13.jpeg'
import bv14 from '../../assets/bv14.jpeg'
function WBProduct() {
  return (
    <div>
    <div className='w-full h-full  md:h-[500px] md:flex px-5 sm:px-10 py-10 sm:py-20 bg-text-base'>
        <div className='w-[400px] sm:w-11/12 md:w-1/2 h-full w-full sm:flex sm:justify-center  object-contain'> 
            <img className='h-full  sm:h-full sm:w-full px-1' src={bv2} alt="" />
            <div className=' h-full w-full flex flex-col justify-between object-contain'>
            <img className='px-1 pb-1 h-1/2' src={bv14} alt="" />
            <img className='px-1 pt-1 h-1/2 ' src={bv13} alt="" />
            </div>
        </div>
        {/* <div className='w-full h-full overflow-x-hidden max-w-[400px] md:w-1/2 h-full w-full flex justify-center  object-contain'>
          <div className='w-full'><img src={bv2} alt="" /></div>
          <div className='sm:flex '>
            <img src={bv14} className='sm:w-1/2' alt="" />
            <img src={bv13} className='sm:w-1/2' alt="" />
          </div>
        </div> */}
        <div className='w-full  py-10 sm:w-1/2 sm:p-20 text-start flex flex-col items-start justify-center  space-y-4'>
            <p className='text-sm font-playfair text-[#f0a646]'>About Product</p>
            <h1 className='text-4xl font-playfair text-transparent font-medium bg-clip-text bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#d6922c] to-[#141008] '>Crafting the World's Finest Jewelry</h1>
            <p>Every piece in our collection is crafted to stand out. Our unique designs ensure you wear jewelry that not only complements your style but also tells your story. From intricate details to bold statements, we have something for everyone.</p>
            <div>Click View</div>
        </div>
    </div>
    <div className='p-7 bg-secondary md:flex justify-around items-center md:p-14'> 
    <h1 className='text-2xl md:text-4xl text-gray-300 font-playfair'>Check Out How our Product was Processed</h1>
    <div className='text-2xl md:text-4xl text-gray-300'>--x</div>
     </div>
    </div>
  )
}

export default WBProduct