import React from 'react'
import Contact from '../components/Contact'

function ContactUs() {
  return (
    <div>
        <Contact></Contact>
    </div>
  )
}

export default ContactUs