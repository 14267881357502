// src/firebase.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCpIt6by0XQIBuj4kq6WZer69V0KR0nTFM",
  authDomain: "silwery-b3f65.firebaseapp.com",
  projectId: "silwery-b3f65",
  storageBucket: "silwery-b3f65.appspot.com",
  messagingSenderId: "57021601943",
  appId: "1:57021601943:web:312e49b2da4a6e7c41f1d2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
