import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom'
import './App.css';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import Products from './pages/Products';
import ContactUs from './pages/ContactUs';
import About from './pages/About';
import Premium from './pages/Premium';


function App() {
  return (
    <div className="App h-full bg-primary sm:px-10 relative ">

      <Router>
        <div className='w-full h-full relative'>
      <Navbar></Navbar>
        <Routes>
          <Route path='/' Component={Home} ></Route>
          <Route path='/products' Component={Products} ></Route>
          <Route path='/contact' Component={ContactUs} ></Route>
          <Route path='/about' Component={About} ></Route>
          <Route path='/silwery++' Component={Premium} ></Route>
        </Routes>
        </div>
      </Router>
    </div>

  );
}

export default App;
