

import Accordian from './Accordian'
import { Lightbulb, Fingerprint, FactCheckOutlined, WorkspacePremium, TipsAndUpdatesOutlined, WorkspacePremiumOutlined, HandshakeOutlined, DiamondOutlined } from '@mui/icons-material';
function FAQ() {

  return (

    <div className='bg-white  sm:px-20 py-14 flex flex-col justify-center items-center'>

        <div className='py-10 space-y-3 px-[0.7rem] sm:px-5 flex flex-col justify-center items-center'>
            <p className='text-[0.8rem] py-2 text-[#f0a646] font-playfair'>We promise</p>
            <h1 className='text-3xl md:text-4xl font-playfair text-transparent bg-clip-text bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#d6922c] to-[#141008] font-medium'>Silvery promise to their customers</h1>
            <p className='text-[0.6rem] max-w-[200px] w-full sm:max-w-[500px]' >We promise our customers unmatched quality, ensuring each piece is crafted with the finest materials for long-lasting beauty and durability.</p>
        </div>
        

    

        <Accordian IconComponent={FactCheckOutlined} text='100% certified jewelry' desc='100% certified jewelry, guaranteeing authenticity and quality, crafted to reflect sophistication and style, ideal for those who value both elegance and peace of mind in their jewelry choices'></Accordian>
        <Accordian IconComponent={WorkspacePremiumOutlined}   text='Unmatched quality' desc='Crafted with finest materials, ensuring long-lasting beauty and rigorous quality control for excellence in every piece.'></Accordian>
        <Accordian IconComponent={TipsAndUpdatesOutlined}  text = 'Unique designs' desc='Unique designs blend traditional craftsmanship with contemporary flair, telling your personal story with timeless elegance and modern appeal..'></Accordian>
        <Accordian IconComponent={HandshakeOutlined}  text = 'Exceptional service' desc='Dedicated to exceptional service, offering a seamless shopping experience from browsing to receiving beautifully packaged orders.'></Accordian>
        <Accordian IconComponent={DiamondOutlined}  text = 'Durability and beauty in every piece'desc='Jewelry combining durability with aesthetic appeal, designed for lasting beauty and robustness, ensuring years of enjoyment.'></Accordian>
    </div>
  )
}

export default FAQ