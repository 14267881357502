import React from 'react'
import { Link } from 'react-router-dom'
import ProductCorousel from './ProductCorousel'

function BestSellingProduct() {
  return (
    <div className='bg-text-base w-full h-[700px] py-20 px-7 md:px-36 text-start space-y-4 '>
        <p className='text-sm text-[#f0a646]'>Product Offer</p>
        <div className='flex justify-between items-center'>
        <h1 className=' text-4xl sm:text-4xl font-playfair text-transparent bg-clip-text bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#d6922c] to-[#141008] font-medium'>Our Best Seller Product</h1>
        <div className=' hidden sm:flex text-xs border border-gray-700 p-3 font-playfair'><Link to='/products'> Show More Products </Link></div>
        </div>
        <div>
            <div className='w-full list-none flex justify-between items-center overflow-x-scroll py-5 font-playfair'>
                <li className='hover:text-yellow-400 hover:border-b-2 pr-3'>Bracelets</li>
                <li className='hover:text-yellow-400 hover:border-b-2 px-3' >Earing</li>
                <li className='hover:text-yellow-400 hover:border-b-2 px-3'>Chains</li>
                <li className='hover:text-yellow-400 hover:border-b-2 px-3' >Pendants</li>
                <li className='hover:text-yellow-400 hover:border-b-2 px-3'>Diamond</li>
            </div>
            <ProductCorousel></ProductCorousel>
        </div>

    </div>
  )
}

export default BestSellingProduct