import bg from '.././assets/bg.png'
import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import Navbar from '../components/Navbar';
function Premium() {
    const [email, setEmail] = useState('');    
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          await addDoc(collection(db, 'silwery_plus'), {
            email,
            timestamp: serverTimestamp(),
          });
          setEmail('');
          alert('Your form has been submitted successfully!');
        } catch (error) {
          console.error('Error submitting contact form:', error);
        }
      };

  return (

<>

    
    <div className='relative w-full h-screen  '>
        <div className='w-full h-full fixed inset-0 z-20 sm:px-10 bg-teal-700' ><Navbar></Navbar></div>
        <img className='w-full h-full fixed inset-0 z-30 sm:px-10 opacity-10'  src={bg} alt="" />

        <div className='w-96 p-9 absolute top-28 left-5 sm:top-1/4 sm:left-1/3 bg-white rounded-lg space-y-5 z-40'>
        <h1 class="text-xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl  ">
                  Subscribe to Silwery+
              </h1>
              <p>Be the first to know about our latest offers, new jewelry collections, and exclusive premium pieces.</p>
            <form className='space-y-5' onSubmit={handleSubmit} action="">
            <div className='text space-y-3 text-left'>
                <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required/>
             </div>
                  <button type="submit"  class="w-full text-white bg-tertiary hover:bg-teal-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Subscribe</button>

            </form>

        </div>
        
        {/* <section class="w-full dark:bg-gray-900 absolute top-1/3 left-1/3 ">
  <div class=" w-full flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-44 lg:py-0">

      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0   xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Sign in to your account
              </h1>
              <form class="space-y-4 md:space-y-6 text-left" action="#">
                  <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                      <input type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required=""/>
                  </div>


                  <button type="submit" class="w-full text-white bg-tertiary hover:bg-teal-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Subscribe</button>

              </form>
          </div>
      </div>
  </div>
</section> */}
    </div>
    </>
  )
}

export default Premium